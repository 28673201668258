import React from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { useStaticQuery, graphql } from "gatsby"

import styles from "./socialList.module.scss"

// Pass a section to prefix the IDs so they aren't duplicates
const SocialList = ({ section = "social" }) => {
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          globalSets(handle: ["footer", "businessInformation"]) {
            ... on Craft_businessInformation_GlobalSet {
              instagramUrl
              twitterUrl
              linkedinUrl
              vimeoUrl
              substackUrl
            }
          }
        }
      }
    `
  )

  const bizInfo = data.craft.globalSets[0]

  const handleClick = event => {
    trackCustomEvent({
      category: "Global",
      action: "Footer Social Link",
      label: event.currentTarget.title,
    })
  }

  return (
    <ul className={styles.socialList}>
      <li>
        <a
          title="Instagram"
          onClick={handleClick}
          target="_blank"
          rel="noopener noreferrer"
          href={bizInfo.instagramUrl}
        >
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={`${section}-instagram-icon-title`}
            role="img"
          >
            <title id={`${section}-instagram-icon-title`}>Instagram</title>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-694.000000, -8.000000)" fill="#FFFFFF">
                <g transform="translate(694.000000, 8.000000)">
                  <g>
                    <path d="M12.0296889,2.66660741 C11.4963556,2.66660741 11.0815407,3.08142222 11.0815407,3.61534815 C11.0815407,4.14808889 11.4963556,4.5629037 12.0296889,4.5629037 C12.5630222,4.5629037 12.977837,4.14808889 12.977837,3.61534815 C12.977837,3.08142222 12.5630222,2.66660741 12.0296889,2.66660741"></path>
                    <path d="M7.85173333,10.4298667 C6.41173333,10.4298667 5.24432593,9.26186667 5.24432593,7.82245926 C5.24432593,6.38245926 6.41173333,5.21445926 7.85173333,5.21445926 C9.29173333,5.21445926 10.4591407,6.38245926 10.4591407,7.82245926 C10.4591407,9.26186667 9.29173333,10.4298667 7.85173333,10.4298667 M7.8221037,3.79282963 C5.62951111,3.79282963 3.79247407,5.57060741 3.79247407,7.82245926 C3.79247407,10.0150519 5.57025185,11.8514963 7.8221037,11.8514963 C10.0739556,11.8514963 11.9109926,10.0737185 11.9109926,7.82245926 C11.9109926,5.57060741 10.0146963,3.79282963 7.8221037,3.79282963"></path>
                    <path d="M14.2814222,11.140563 C14.2814222,12.0893037 13.9851259,12.9183407 13.3925333,13.4516741 C12.8592,13.9856 12.0295704,14.2818963 11.0814222,14.2818963 L4.62216296,14.2818963 C3.67401481,14.2818963 2.90364444,13.9856 2.31105185,13.4516741 C1.71845926,12.8590815 1.42216296,12.0294519 1.42216296,11.0813037 L1.42216296,4.56278519 C1.42216296,3.61522963 1.71845926,2.78500741 2.25179259,2.25167407 C2.78512593,1.71893333 3.61475556,1.42263704 4.5629037,1.42263704 L11.0814222,1.42263704 C12.0888296,1.42263704 12.8592,1.71893333 13.4517926,2.31152593 C13.9851259,2.90411852 14.2814222,3.6738963 14.2814222,4.62204444 L14.2814222,11.140563 Z M14.4592,1.30352593 C13.6295704,0.473896296 12.5036444,-0.000177777778 11.1406815,-0.000177777778 L4.62216296,-0.000177777778 C1.83697778,-0.000177777778 -5.92592594e-05,1.89611852 -5.92592594e-05,4.62204444 L-5.92592594e-05,11.140563 C-5.92592594e-05,12.5041185 0.414755556,13.6893037 1.30364444,14.5183407 C2.13327407,15.348563 3.31845926,15.7633778 4.62216296,15.7633778 L11.0814222,15.7633778 C12.3851259,15.7633778 13.5703111,15.348563 14.3999407,14.5183407 C15.2295704,13.6893037 15.7036444,12.5041185 15.7036444,11.140563 L15.7036444,4.62204444 C15.7036444,3.25908148 15.2888296,2.13374815 14.4592,1.30352593 L14.4592,1.30352593 Z"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
      </li>
      <li>
        <a
          title="LinkedIn"
          onClick={handleClick}
          target="_blank"
          rel="noopener noreferrer"
          href={bizInfo.linkedinUrl}
        >
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={`${section}-linkedin-icon-title`}
            role="img"
          >
            <title id={`${section}-linkedin-icon-title`}>LinkedIn</title>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-813.000000, -8.000000)" fill="#FFFFFF">
                <g transform="translate(694.000000, 8.000000)">
                  <path d="M131.022354,5.04732744 C134.379672,5.04732744 135,7.2602572 135,10.1387455 L135,16 L131.684597,16 L131.684597,10.8029044 C131.684597,9.56346776 131.66364,7.96920654 129.961928,7.96920654 C128.237164,7.96920654 127.973804,9.31991952 127.973804,10.7140233 L127.973804,16 L124.661893,16 L124.661893,5.31257108 L127.840377,5.31257108 L127.840377,6.77386055 L127.885784,6.77386055 C128.327978,5.93333917 129.409361,5.04732744 131.022354,5.04732744 Z M122.582256,5.31257108 L122.582256,16 L119.26336,16 L119.26336,5.31257108 L122.582256,5.31257108 Z M120.923856,0 C121.984282,0 122.845616,0.86291663 122.845616,1.92599073 C122.845616,2.98976468 121.984282,3.85268131 120.923856,3.85268131 C119.85854,3.85268131 119,2.98976468 119,1.92599073 C119,0.86291663 119.85854,0 120.923856,0 Z"></path>
                </g>
              </g>
            </g>
          </svg>
        </a>
      </li>
      <li>
        <a
          title="Substack"
          onClick={handleClick}
          target="_blank"
          rel="noopener noreferrer"
          href={bizInfo.substackUrl}
        >
          <svg
            width="18px"
            height="16px"
            viewBox="0 0 21 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={`${section}-substack-icon-title`}
            role="img"
          >
            <title id={`${section}-substack-icon-title`}>Substack</title>
            <g fill="#FFFFFF"><title></title>
              <path d="M20.9991 5.40625H0V8.24275H20.9991V5.40625Z"></path>
              <path d="M0 10.8125V24.0004L10.4991 18.1107L21 24.0004V10.8125H0Z"></path>
              <path d="M20.9991 0H0V2.83603H20.9991V0Z"></path>
            </g>
            {/*<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">*/}
            {/*  <g transform="translate(-871.000000, -8.000000)" fill="#FFFFFF">*/}
            {/*    <g transform="translate(694.000000, 8.000000)">*/}
            {/*      <path d="M194.991252,3.70156737 C194.910813,5.50242374 193.687359,7.96754463 191.320325,11.0975082 C188.872854,14.3656433 186.802262,16 185.109114,16 C184.059474,16 183.171274,15.0050485 182.446201,13.0145675 C181.961882,11.1894299 181.478125,9.36429231 180.993243,7.53915472 C180.454361,5.54925179 179.877228,4.55314407 179.259032,4.55314407 C179.12403,4.55314407 178.653211,4.84451859 177.846012,5.424377 L177,4.30339449 C177.8882,3.50153645 178.764025,2.69967841 179.626349,1.896086 C180.810991,0.844478378 181.701441,0.291213675 182.293762,0.235135643 C183.694406,0.0969639986 184.556731,1.08093111 184.880173,3.18703697 C185.230053,5.45964256 185.472494,6.87315583 185.608058,7.42642054 C186.012501,9.31226114 186.456882,10.2540252 186.941764,10.2540252 C187.318082,10.2540252 187.884527,9.64236997 188.63885,8.41848138 C189.392611,7.19574905 189.796492,6.26554747 189.850492,5.62556416 C189.958494,4.57048779 189.554613,4.0415043 188.63885,4.0415043 C188.207969,4.0415043 187.763588,4.14325413 187.306832,4.3444413 C188.191657,1.36710251 189.88143,-0.0787857085 192.376153,0.00330790469 C194.225679,0.0593859362 195.098128,1.29194638 194.991252,3.70156737"></path>*/}
            {/*    </g>*/}
            {/*  </g>*/}
            {/*</g>*/}
          </svg>
        </a>
      </li>
    </ul>
  )
}

export default SocialList
