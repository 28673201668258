import "intersection-observer"
import React from "react"
import "picturefill"
import "picturefill/dist/plugins/mutation/pf.mutation.min"
import cssVars from "css-vars-ponyfill"

import Header from "./header"
import Footer from "./footer"
import Seo from "../components/seo"
import "../scss/global.scss"
import {
  ThemeProvider,
  zeusTheme as zTheme,
  zeusAltTheme as zATheme,
  athenaTheme as aTheme,
} from "../hooks/useTheme"

export const zeusTheme = zTheme
export const zeusAltTheme = zATheme
export const athenaTheme = aTheme

// FIXME why does this render when typing in an input
const Layout = ({
  seo,
  children,
  theme,
  mode = "zeus",
  shyHeader = true,
  hideBranding = false,
  isEndlesss = false
}) => {
  theme = theme != null ? theme : mode === "athena" ? athenaTheme : zeusTheme
  React.useEffect(() => {
    cssVars({
      silent: true,
      variables: {
        "--theme-foreground": theme.foreground,
        "--theme-background": theme.background,
        "--header-position": theme.headerPosition,
      },
    })
  }, [theme.foreground, theme.background])
  return (
    <ThemeProvider theme={theme}>
      <Seo data={seo} />
      <div className={`page`}>
        {!hideBranding && <Header mode={mode} isEndlesss={isEndlesss} shyHeader={shyHeader} />}

        <main className="page-content">{children}</main>

        {!isEndlesss && (
          <div className="page-footer">
            <Footer />
          </div>
          ) }
      </div>
    </ThemeProvider>
  )
}

export default Layout
