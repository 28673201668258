import React from "react"

const Logo = ({ color = "#1a1a1a", id = "athena-logo" }) => {
  return (
    <>
      <svg
        width="110px"
        height="56px"
        viewBox="0 0 110 56"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby={id}
        role="img"
      >
        <title id={id}>Athena Jones</title>
        <g
          fill={color}
          fillRule="nonzero"
          transform="translate(-50.000000, -124.000000)"
        >
          <g transform="translate(50.000000, 124.000000)">
            <path d="M12.0531124,24.4890511 L12.0847894,24.3633807 L14.056685,22.6275581 L13.8666227,18.5275608 L6.10574879,18.5275608 L3.81549905,22.1547231 L5.69236346,24.3633807 L5.66068643,24.4890511 L0,24.4890511 L0.0316770365,24.3633807 L1.81192649,22.2803935 L12.9685788,5.31017484 L12.6518084,2.53442954 L12.6834854,2.40875912 L18.7575572,2.40875912 L19.8076509,22.6275581 L20.4744526,24.3633807 L20.4427755,24.4890511 L12.0531124,24.4890511 Z M6.96419648,17.1703203 L13.8032687,17.1703203 L13.3566224,7.01300897 L6.96419648,17.1703203 Z"></path>
            <path d="M22.6714582,19.9419456 L25.2711499,9.50944851 L22.7661646,9.50944851 L23.1797161,7.72383457 L25.7146918,7.72383457 L26.5039121,4.53387656 L32.0505525,2.81021898 L32.2415438,2.81021898 L31.0072032,7.72383457 L34.5255474,7.72383457 L34.1135744,9.50944851 L30.5636614,9.50944851 L27.7082623,20.8665251 C27.5504182,21.5369246 27.6766935,21.8562381 28.0570977,21.8562381 C28.9126125,21.8562381 31.1950377,19.9737181 32.8744985,18.1547431 L33.0023522,18.218288 C31.7017171,22.2708691 28.9757501,24.8905109 26.0288015,24.8905109 C23.369129,24.8857451 21.9422186,22.8125901 22.6714582,19.9419456 Z"></path>
            <path d="M34.5255474,24.4151878 L39.0586574,3.20093045 L37.0472986,1.52071831 L37.0790987,1.36280364 L44.5521316,0 L45.095914,0 L42.1877912,12.6821275 L42.3165818,12.6821275 C43.9447489,9.19379233 46.1787088,7.35566552 49.2442423,7.35566552 C51.5433924,7.35566552 52.7883679,9.09272694 52.3097758,11.191413 L50.0424258,21.845916 L50.1712164,21.845916 L53.2351599,19.1203088 L53.3941606,19.2150576 C52.3399859,22.512316 49.7848447,24.8905109 47.2948937,24.8905109 C45.5713261,24.8905109 44.3263506,23.5908732 44.644352,22.1317416 L46.8496917,12.0488896 C47.137483,10.8108386 46.8814919,10.3355154 45.9561078,10.3355154 C44.3915409,10.3355154 42.2513915,13.1890336 41.453208,16.9000284 L39.8250408,24.4151878 L34.5255474,24.4151878 Z"></path>
            <path d="M54.5985401,19.5872642 C54.5985401,13.038534 59.9378324,7.22627737 65.3424127,7.22627737 C67.8997951,7.22627737 69.0510949,8.49880208 69.0510949,10.1960319 C69.0510949,13.1673771 65.4379562,14.4446738 60.1336966,15.7219704 C60.0094241,16.4824623 59.9450004,17.2515123 59.9410171,18.0220588 C59.9410171,20.4812128 60.8040939,21.631257 62.2754646,21.631257 C63.9060746,21.631257 65.952299,20.2251172 67.6163492,17.1917365 L67.7437406,17.2553627 C66.5287451,22.8131144 62.8184705,24.8905109 59.7180822,24.8905109 C56.8103734,24.8889203 54.5985401,23.06921 54.5985401,19.5872642 Z M60.2579033,15.0189005 C63.1990523,14.0295126 65.0541896,12.4324941 65.0541896,10.0369663 C65.0541896,9.0459877 64.7357111,8.50357405 64.0987541,8.50357405 C62.4968072,8.50357405 60.9298929,11.7612373 60.2579033,15.0189005 L60.2579033,15.0189005 Z"></path>
            <path d="M80.8003148,22.1113849 L82.997487,11.9541324 C83.2846265,10.7069459 83.0292151,10.2281153 82.1059268,10.2281153 C80.5195209,10.2281153 78.3794593,13.1026894 77.6148116,16.8410673 L75.9903319,24.4116804 L70.7012545,24.4116804 L73.6012046,10.8039846 L73.4727057,10.8039846 L70.3823869,13.5194787 L70.2554745,13.42244 C71.4024459,9.7492848 73.8867577,7.70510791 77.6782679,7.70510791 L78.3794593,7.70510791 L78.3144166,12.6238588 L78.4730572,12.6238588 C80.0594632,9.07796756 82.3280237,7.22627737 85.3532998,7.22627737 C87.6789709,7.22627737 88.8893987,8.97615642 88.4436186,11.0903285 L86.151262,21.8234502 L86.3099026,21.8234502 L89.3669069,19.0777309 L89.5255474,19.1731789 C88.4737603,22.4947675 85.9259924,24.8905109 83.4400942,24.8905109 C81.723603,24.8905109 80.4814472,23.5812833 80.8003148,22.1113849 Z"></path>
            <path d="M91.1313869,20.4108386 C91.1313869,13.9031424 96.9982179,7.33379934 102.705547,7.33379934 C104.07,7.33379934 105.274951,7.68313159 106.067723,8.3802154 L109.905246,6.82481752 L110,6.88962576 L108.450778,11.4957532 L106.167214,21.8429428 L106.325137,21.8429428 L109.368309,19.1146737 L109.526232,19.209515 C108.479204,22.5416072 106.037718,24.8905109 103.596231,24.8905109 C101.882769,24.8905109 100.614649,23.5579902 100.963659,22.0974337 L101.788016,18.5108506 L101.661677,18.5108506 C99.4728678,22.7644844 97.1893045,24.8905109 94.6214802,24.8905109 C92.3094908,24.8857689 91.1313869,23.2987572 91.1313869,20.4108386 Z M97.5683192,21.9030089 C99.1475469,21.9030089 101.944359,17.2684291 102.737132,13.20922 L103.368823,10.0020022 L100.396716,8.7927752 C98.4289983,10.5710502 96.5591926,15.9058752 96.5591926,19.5224914 C96.5591926,21.1379555 96.9397865,21.9030089 97.5730568,21.9030089 L97.5683192,21.9030089 Z"></path>
            <path d="M14.8540146,47.2214725 C14.8540146,53.1742404 11.8076658,55.8029197 7.56593405,55.8029197 C3.04100298,55.8029197 0.401459854,52.6365202 0.401459854,48.5492157 C0.401459854,48.3284508 0.496902729,48.2338372 0.714387314,48.2338372 L3.35549508,48.2338372 C3.5745443,48.2338372 3.66842254,48.3284508 3.66842254,48.5492157 C3.66842254,51.1778949 5.30190388,52.6349433 7.56436942,52.6349433 C10.2336407,52.6349433 11.5854873,50.9240153 11.5854873,47.2514334 L11.5854873,33.6365463 C11.5854873,33.4157814 11.6793655,33.3211679 11.8984147,33.3211679 L14.5410871,33.3211679 C14.7601364,33.3211679 14.8540146,33.4157814 14.8540146,33.6365463 L14.8540146,47.2214725 Z"></path>
            <path d="M25.8941606,39.3430657 C30.5924895,39.3430657 34.1240876,42.6846579 34.1240876,47.5729927 C34.1240876,52.4613275 30.5877786,55.8029197 25.8941606,55.8029197 C21.2005426,55.8029197 17.6642336,52.4597572 17.6642336,47.5729927 C17.6642336,42.6862282 21.1911208,39.3430657 25.8941606,39.3430657 Z M25.8941606,52.8381988 C28.7944993,52.8381988 30.8453073,50.7261492 30.8453073,47.5729927 C30.8453073,44.4198362 28.7897884,42.3062163 25.8941606,42.3062163 C22.9985328,42.3062163 20.9430139,44.4198362 20.9430139,47.5729927 C20.9430139,50.7261492 22.989111,52.8381988 25.8941606,52.8381988 Z"></path>
            <path d="M36.5328467,39.9754704 C36.5328467,39.7568613 36.6277127,39.6631718 36.8490667,39.6631718 L39.3250691,39.6631718 C39.5480041,39.6631718 39.641289,39.7568613 39.641289,39.9754704 L39.641289,41.3808141 C40.9741562,40.1316197 42.5947836,39.3430657 44.6897409,39.3430657 C48.1823904,39.3430657 50.1824818,41.3808141 50.1824818,44.8301521 L50.1824818,55.0829153 C50.1824818,55.3030858 50.0876158,55.3952139 49.8662618,55.3952139 L47.3918405,55.3952139 C47.1689054,55.3952139 47.0756205,55.3015243 47.0756205,55.0829153 L47.0756205,45.7451871 C47.0756205,43.3623487 45.8692414,42.295849 43.900772,42.295849 C41.6461236,42.295849 39.6460323,44.0837585 39.6460323,47.658016 L39.6460323,55.0891613 C39.6460323,55.3093318 39.5511663,55.4014599 39.3298124,55.4014599 L36.8506478,55.4014599 C36.6277127,55.4014599 36.5344278,55.3077703 36.5344278,55.0891613 L36.5328467,39.9754704 Z"></path>
            <path d="M65.378802,50.4749017 C65.5049976,50.2236542 65.5996444,50.1906779 65.8220642,50.1906779 L68.5147635,50.1906779 C68.5754162,50.1840488 68.6357976,50.2052977 68.6787894,50.2484004 C68.7217813,50.2915032 68.7426966,50.3517606 68.7356058,50.4120898 C68.734728,50.519808 68.7133164,50.6263811 68.672508,50.7261492 C67.3427215,54.2577474 64.744669,55.8029197 61.0707986,55.8029197 C56.3195331,55.8029197 52.9927007,52.3341335 52.9927007,47.5729927 C52.9927007,42.8118519 56.3826309,39.3430657 61.0376723,39.3430657 C66.072878,39.3430657 69.0510949,43.1573173 69.0510949,47.6656402 L69.0510949,48.2654937 C69.0510949,48.4853353 68.9564482,48.5795531 68.7356058,48.5795531 L56.2958714,48.5795531 C56.6429094,51.2286443 58.4175355,52.8366285 61.0786858,52.8366285 C63.2240116,52.8397691 64.5869244,52.1456978 65.378802,50.4749017 Z M56.4141798,45.7451669 L65.6627422,45.7451669 C65.1248333,43.4745173 63.2871094,42.3077866 61.0392497,42.3077866 C59.0753302,42.3077866 57.0467354,43.3488935 56.4141798,45.7451669 Z"></path>
            <path d="M77.7760229,55.8029197 C73.9973909,55.8029197 71.3108131,53.7615335 71.0603562,50.4733314 C71.0502196,50.3873058 71.0797244,50.3013389 71.1404104,50.2400808 C71.2010964,50.1788228 71.2862606,50.1490398 71.3714828,50.159272 L73.9973909,50.159272 C74.2151795,50.159272 74.2774048,50.2550601 74.3085175,50.4733314 C74.590087,52.1441275 75.9652664,52.8381988 77.8709165,52.8381988 C79.7127857,52.8381988 81.0568525,52.3341335 81.0568525,50.851773 C81.0568525,49.7525651 79.9010173,49.1809769 76.8706447,48.6769115 C73.1215697,48.0487927 71.4663764,46.6261036 71.4663764,44.0728005 C71.4663764,40.761044 74.496749,39.3430657 77.5582343,39.3430657 C81.2124157,39.3430657 83.5240861,41.3294915 83.8056556,44.0728005 C83.8157922,44.1588261 83.7862874,44.244793 83.7256014,44.3060511 C83.6649154,44.3673091 83.5797512,44.3970921 83.494529,44.3868599 L80.8375082,44.3868599 C80.6197197,44.3868599 80.588607,44.2910718 80.5263817,44.0728005 C80.1203615,42.8746638 78.9707489,42.3062163 77.4026711,42.3062163 C75.966822,42.3062163 74.5294173,42.7474698 74.5294173,43.9142005 C74.5294173,45.1123372 75.4985765,45.7121907 78.6533998,46.2790679 C81.9326737,46.8789214 84.3065693,47.8572165 84.3065693,50.851773 C84.3050137,53.9421177 81.6184359,55.8029197 77.7760229,55.8029197 Z"></path>
          </g>
        </g>
      </svg>
    </>
  )
}

export default Logo
