import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import widont from "widont"

import styles from "./footer.module.scss"
import Bird from "./bird"
import Bcorps from "./bcorps"
import SocialList from "./socialList"
import NewsletterSignup from "./newsletterSignup"

//
// A navigation link can be:
//  - disabled: no page or URL selected
//  - external: linkText and externalUrl are defined
//  - internal: link.page is defined
//      If linkText isn't defined, use the chosen page's title
//
//  Return null if it doesn't match these conditions
//
function NavLink(props) {
  const link = props.link
  const isDisabled =
    link.linkText && link.externalUrl < 1 && link.page.length < 1
  const isExternal = link.linkText && link.externalUrl
  const isInternal = link.page.length > 0

  if (isDisabled) {
    return <div className={styles.navItemDisabled}>{link.linkText}</div>
  } else if (isExternal) {
    return (
      <a
        target="_blank"
        className="link"
        rel="noopener noreferrer"
        href={link.externalUrl}
      >
        {link.linkText}
      </a>
    )
  } else if (isInternal) {
    return link.page.map(page => {
      return (
        <Link
          key={`footer-nav-page-${page.slug}`}
          className="link"
          to={`/${page.uri}`}
        >
          {/* If there's no linkText use the page's title */}
          {link.linkText ? link.linkText : page.title}
        </Link>
      )
    })
  }
  console.warn("Ivalid link.", link)
  return null
}

const Footer = props => {
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          globalSets(handle: ["footer", "businessInformation"]) {
            ... on Craft_businessInformation_GlobalSet {
              phoneNumber
              generalEmailAddress
              minneapolisAddress
            }
            ... on Craft_footer_GlobalSet {
              newsletterHeadline
              bCorpHeading
              bCorpBody
              bCorpLink {
                target
                text
                url
                element {
                  uri
                }
              }
              footerSecondaryNavigation {
                ... on Craft_footerSecondaryNavigation_BlockType {
                  id
                  linkText
                  externalUrl
                  page {
                    title
                    slug
                    uri
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const bizInfo = data.craft.globalSets[0]
  const footer = data.craft.globalSets[1]

  return (
    <footer className={styles.footer}>
      <h2 className="screen-reader-only">Footer</h2>
      <div className="wrapper">
        <div className="columns is-multiline">
          <div
            className={`${styles.section} ${styles.sectionLogo} column is-12-tablet is-2-desktop`}
          >
            <Bird />
          </div>
          {/* .section-logo */}

          <div
            className={`${styles.section} ${styles.sectionAddress} column is-6-tablet is-3-desktop is-3-fullhd`}
          >
            <div className={styles.social}>
              <SocialList section="footer" />
            </div>
            <p
              style={{ whiteSpace: "pre-line" }}
              dangerouslySetInnerHTML={{
                __html: bizInfo.minneapolisAddress,
              }}
            />
          </div>
          {/* .section-address */}

          <div
            className={`${styles.section} ${styles.sectionContact} column is-6-tablet is-4-desktop is-4-fullhd`}
          >
            <div>
              <div>
                {/*<p>{bizInfo.phoneNumber}</p>*/}
                <p></p>
                <p>
                  <a
                    className="link"
                    href={`mailto:${bizInfo.generalEmailAddress}`}
                  >
                    {bizInfo.generalEmailAddress}
                  </a>
                </p>
              </div>
            </div>
          </div>
          {/* .section-contact */}

          <div
            className={`${styles.section} ${styles.sectionForm} column is-12-mobile is-8-tablet is-5-desktop`}
          >
            <h3 className="body-large mb-1">{footer.newsletterHeadline}</h3>
            <NewsletterSignup />
          </div>
          {/* .section-form */}

          <div
            className={`${styles.section} ${styles.sectionBcorp} column is-12-mobile is-8-tablet is-offset-5-desktop is-5-desktop`}
          >
            <div className={styles.bcorp}>
              <div className={styles.bcorpBody}>
                <h3 className="body-large mb-1">{footer.bCorpHeading}</h3>
                <div className="body-medium body-after-tablet">
                  {footer.bCorpBody && <p>{widont(footer.bCorpBody)}</p>}

                  {footer.bCorpLink.element && (
                    <div>
                      <Link
                        className="link"
                        target={footer.bCorpLink.target}
                        to={`/${footer.bCorpLink.element.uri}/`}
                      >
                        {footer.bCorpLink.text}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.bcorpImage}>
                <Bcorps />
              </div>
            </div>
          </div>
          {/* .section-bcorp */}

          <div
            className={`${styles.section} ${styles.sectionSmall} column is-12-tablet`}
          >
            <div className={styles.small}>
              <ul className={styles.smallNav}>
                {footer.footerSecondaryNavigation.map(item => {
                  return (
                    <li key={`footer-nav-${item.id}`}>
                      <NavLink link={item} />
                    </li>
                  )
                })}
              </ul>
              <p>©{new Date().getFullYear()} Zeus Jones, Inc</p>
            </div>
          </div>
          {/* .section-small */}
        </div>
        {/* .sections */}
      </div>
    </footer>
  )
}

export default Footer
