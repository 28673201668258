import React from "react"

const Logo = ({ color = "#FFFFFF", id = "zj-logo", width = 88 }) => {
  return (
    <>
      <svg
        width={`${width}px`}
        viewBox="0 0 88 56"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby={id}
        role="img"
      >
        <title id={id}>Zeus Jones</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            fill={color}
            fillRule="nonzero"
            transform="translate(-52.000000, -106.000000)"
          >
            <g transform="translate(52.000000, 106.000000)">
              <polygon points="0 23.1201495 0.0328971963 22.9885607 17.3730093 0.986915888 9.0171215 0.986915888 5.02011215 3.53480374 4.82272897 3.53480374 5.58265421 0 20.2465794 0 20.2136822 0.131588785 2.90646729 22.1299439 12.4186916 22.1299439 16.5801869 19.6198879 16.7446729 19.6198879 15.9847477 23.1201495"></polygon>
              <path d="M19.3879626,18.8928598 C19.3879626,13.047028 23.8471776,6.44127103 27.9412336,6.44127103 C29.4610841,6.44127103 30.5170841,7.36568224 30.5170841,8.88553271 C30.5170841,11.5271776 27.3474393,13.7740561 21.236785,15.2922617 C21.0293338,16.2252531 20.9190881,17.1772245 20.9078131,18.1329346 C20.9078131,20.8091215 21.8651215,22.1957383 23.4507664,22.1957383 C25.3341308,22.1957383 27.3819813,20.3469159 29.4298318,16.7463178 L29.5614206,16.8121121 C28.2077009,21.1397383 25.7963364,23.6168972 22.9819813,23.6168972 C20.6429907,23.6168972 19.3879626,21.9654579 19.3879626,18.8928598 Z M21.4029159,14.5323364 C26.7207477,13.1786168 29.0005234,11.592972 29.0005234,9.38063551 C29.0005234,8.12560748 28.3063925,7.33278505 27.2158505,7.33278505 C24.9706168,7.33278505 22.4605607,10.7014579 21.4029159,14.5323364 Z"></path>
              <path d="M41.2859813,22.5921495 L42.5739065,17.2085234 L42.475215,17.2085234 C40.0967477,21.3700187 37.6195888,23.6234766 35.4023178,23.6234766 C33.6505421,23.6234766 32.8906168,22.3339065 33.3873645,19.9899813 L35.798729,8.49570093 L35.6654953,8.49570093 L33.0962243,10.536972 L32.9975327,10.4037383 C34.4499439,8.02691589 35.540486,6.93637383 36.8942056,6.93637383 L37.7528224,6.93637383 L34.9795888,20.1478879 C34.7147664,21.4358131 35.1440748,22.1628411 36.1309907,22.1628411 C38.4765607,22.1628411 42.3074393,17.7365234 43.1331589,14.0372336 L44.6859065,6.93637383 L46.3044486,6.93637383 L43.0344673,22.0312523 L43.1331589,22.0312523 L45.677757,20.0162991 L45.7764486,20.1478879 C44.5214206,22.2615327 43.0673645,23.6168972 41.9784673,23.6168972 C41.3863178,23.6168972 41.1560374,23.1925234 41.2859813,22.5921495 Z"></path>
              <path d="M47.0676577,21.3700187 C47.0657873,20.7921895 47.5155824,20.3135151 48.0924112,20.2794766 L51.0317757,22.9227664 C53.2770093,22.9227664 54.7310654,21.6348411 54.7310654,19.5869907 C54.7310654,16.1508785 50.5366729,15.0290841 50.5366729,11.2294579 C50.5366729,8.52201869 52.7819065,6.44127103 55.721271,6.44127103 C57.3069159,6.44127103 58.4303551,7.06796262 58.4303551,7.92164486 C58.4383832,8.37490616 58.1165409,8.7671079 57.6704299,8.84770093 L54.7310654,7.40186916 C52.9792897,7.40186916 51.8245981,8.5911028 51.8245981,10.407028 C51.8245981,13.9089346 56.0189907,15.065271 56.0189907,18.9602991 C56.0189907,21.7351776 53.938243,23.6185421 51.1304673,23.6185421 C49.0168224,23.6168972 47.0676577,22.5345794 47.0676577,21.3700187 Z"></path>
              <path d="M15.3597009,47.2074766 C15.3597009,53.2194393 12.0897196,55.6965981 7.89368224,55.6965981 C3.26998131,55.6965981 0.560897196,52.4924112 0.560897196,48.3309159 C0.560897196,48.1006355 0.661233645,48.0019439 0.889869159,48.0019439 L3.00515888,48.0019439 C3.23708411,48.0019439 3.33413084,48.1022804 3.33413084,48.3309159 C3.33413084,51.04 5.08426168,53.0549533 7.89203738,53.0549533 C11.0962243,53.0549533 12.5831776,51.204486 12.5831776,47.2420187 L12.5831776,32.5682243 C12.5831776,32.3362991 12.6818692,32.2392523 12.9121495,32.2392523 L15.0290841,32.2392523 C15.2593645,32.2392523 15.3580561,32.3379439 15.3580561,32.5682243 L15.3597009,47.2074766 Z"></path>
              <path d="M26.8539813,38.4485981 C31.609271,38.4485981 35.2756636,41.817271 35.2756636,47.0693084 C35.2756636,52.3213458 31.609271,55.6900187 26.8539813,55.6900187 C22.0986916,55.6900187 18.4306542,52.3213458 18.4306542,47.0693084 C18.4306542,41.817271 22.0970467,38.4485981 26.8539813,38.4485981 Z M26.8539813,53.2523364 C30.2555514,53.2523364 32.5682243,50.7751776 32.5682243,47.0758879 C32.5682243,43.3765981 30.2555514,40.8994393 26.8539813,40.8994393 C23.4524112,40.8994393 21.1397383,43.3765981 21.1397383,47.0758879 C21.1397383,50.7751776 23.4507664,53.245757 26.8539813,53.245757 L26.8539813,53.2523364 Z"></path>
              <path d="M38.2808224,39.1098318 C38.2808224,38.8779065 38.3811589,38.7808598 38.6097944,38.7808598 L40.5260561,38.7808598 C40.7563364,38.7808598 40.855028,38.8795514 40.855028,39.1098318 L40.855028,40.9915514 C42.1429533,39.4404486 43.9588785,38.4485981 46.3373458,38.4485981 C49.8047103,38.4485981 52.084486,40.5951402 52.084486,44.4605607 L52.084486,55.0304299 C52.084486,55.2607103 51.9857944,55.3594019 51.755514,55.3594019 L49.8392523,55.3594019 C49.608972,55.3594019 49.5102804,55.2607103 49.5102804,55.0304299 L49.5102804,44.856972 C49.5102804,42.180785 48.1220187,40.8928598 45.7451963,40.8928598 C42.9703178,40.8928598 40.8566729,42.9078131 40.8566729,46.9048224 L40.8566729,55.0304299 C40.8566729,55.2607103 40.7563364,55.3594019 40.5277009,55.3594019 L38.6114393,55.3594019 C38.3811589,55.3594019 38.2824673,55.2607103 38.2824673,55.0304299 L38.2808224,39.1098318 Z"></path>
              <path d="M58.1326355,47.8654206 C58.3974579,51.1025047 60.4453084,53.2490467 63.6823925,53.2490467 C65.9605234,53.2490467 67.6448598,52.3575327 68.5363738,50.2109907 C68.6367103,49.9461682 68.7682991,49.913271 68.9985794,49.913271 L71.1467664,49.913271 C71.2100112,49.9063272 71.272973,49.928585 71.3178022,49.9737344 C71.3626314,50.0188838 71.3844405,50.0820024 71.3770467,50.1451963 C71.3764142,50.2580582 71.3540769,50.3697447 71.3112523,50.4741682 C70.0562243,53.9760748 67.2813458,55.6933084 63.6149533,55.6933084 C58.5619439,55.6933084 55.357757,51.9282243 55.357757,47.0725981 C55.357757,42.216972 58.8267664,38.4518879 63.6149533,38.4518879 C68.6367103,38.4518879 71.7076636,42.416 71.7076636,47.0051589 L71.7076636,47.5364486 C71.7076636,47.7683738 71.608972,47.8654206 71.3786916,47.8654206 L58.1326355,47.8654206 Z M58.2642243,45.4211589 L68.932785,45.4211589 C68.404785,42.7120748 66.3240374,40.8961442 63.6149533,40.8961442 C61.1048972,40.8928598 58.8596636,42.4127103 58.2642243,45.4178692 L58.2642243,45.4211589 Z"></path>
              <path d="M81.2198879,55.6900187 C77.3561121,55.6900187 74.2506168,53.4118879 74.2506168,50.3063925 C74.2398988,50.2162822 74.271096,50.1262332 74.3352627,50.0620665 C74.3994295,49.9978997 74.4894784,49.9667025 74.5795888,49.9774206 L76.6932336,49.9774206 C76.9251589,49.9774206 76.9909533,50.077757 77.0222056,50.3063925 C77.287028,52.2226542 78.8315514,53.245757 81.3152897,53.245757 C83.529271,53.245757 85.1478131,52.553271 85.1478131,50.8343925 C85.1478131,49.115514 83.693757,48.5233645 80.3250841,47.9937196 C76.3642617,47.3719626 74.6766355,46.0791028 74.6766355,43.1726355 C74.6766355,40.1329346 77.451514,38.4485981 80.9534206,38.4485981 C84.6527103,38.4485981 86.9308411,40.3319626 87.3272523,43.2055327 C87.3379704,43.295643 87.3067732,43.385692 87.2426064,43.4498588 C87.1784397,43.5140255 87.0883907,43.5452227 86.9982804,43.5345047 L85.1511028,43.5345047 C84.9191776,43.5345047 84.8862804,43.4341682 84.8221308,43.2055327 C84.3286729,41.5869907 82.6426916,40.8928598 80.8580187,40.8928598 C78.7443738,40.8928598 77.2590654,41.6198879 77.2590654,43.1051963 C77.2590654,44.7237383 78.5749533,45.2862804 81.9156636,45.8800748 C85.0869533,46.4426168 87.8601869,47.3604486 87.8601869,50.5054206 C87.8585421,53.8411963 85.0853084,55.6900187 81.2198879,55.6900187 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}

export default Logo
